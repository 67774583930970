import styled from "styled-components";
import { BP, Rem } from "../../commons/Theme";

export const StyledSponsorContainer = styled.div`
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 510px;
  max-width: 100%;

  & > img {
    max-width: 100%;
  }
`;

export const StyledContainer = styled.div`
  max-width: ${Rem(1488)};
  padding: 0 ${Rem(20)};
  display: flex;
  margin: auto;

  @media (${BP.ipad}) {
    max-width: 1248px;
    padding: 0 ${Rem(32)};
  }
`;

export const StyledQuantityContainer = styled(StyledContainer)`
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-direction: column;

  @media (${BP.ipad}) {
    flex-direction: row;
    align-items: initial;
  }
`;

export const StyledQuantityDescription = styled.div`
  flex: 1;
  text-align: left;
  width: 100%;
`;

export const StyledWhoHasGenerated = styled(StyledContainer)`
  gap: 20px;
  justify-content: space-between;
  flex-direction: column;

  @media (${BP.ipad}) {
    flex-direction: row;
  }
`;

export const StyledWhoHasGeneratedItems = styled.div`

  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  
  @media (${BP.ipad}) {
    display: flex;
    flex-wrap: wrap;

    & img {
      margin: initial;
    }
  }

  & img {
    max-width: 180px;
    margin: auto
  }
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  & svg {
    width: 60px;
  }
  @media (${BP.ipad}) {
    display: block;

    & svg {
      width: 150px;
    }
  }
`;

export const StyledTextContainer = styled.div`
  

`;
