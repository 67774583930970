import React, { useContext } from "react";
import {
  Hero,
  HeroImageContainer,
  HeroHeader,
  Button,
  // NewsletterBox,
  // HeroFooter,
} from "@3beehivetech/components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { DataContext } from "../../contexts/DataContext";
import SectionTwoCol from "../../components/SectionTwoCol/SectionTwoCol";
import Intro from "../../components/Intro_v2/Intro_v2";
import BusinessIconText from "../../components/BusinessIconText/BusinessIconText";
import ContactFormPipe from "../../components/ContactFormPipe/ContactFormPipe";
import Meta from "../../components/Meta/Meta";
import useNavigate from "../../hooks/useNavigate";
import useNewsletterListId from "../../hooks/useNewsletterListId";
import { toast } from "react-toastify";
import BusinessIconTextItem from "../../components/BusinessIconText/BusinessIconTextItem";
import {
  StyledBusinessIconGroup, StyledBusinessIconGroupItems, StyledBusinessIconGroupTitle, StyledHighlightedBusinessContainer, 
} from "../../components/BusinessIconText/style";
import {
  StyledQuantityContainer, StyledQuantityDescription, StyledSponsorContainer, StyledTextContainer, StyledTitleContainer, StyledWhoHasGenerated, StyledWhoHasGeneratedItems, 
} from "../../components/BeEnergy/style";
import { SectionDivider, StyledParagraph, StyledTitle } from "../../components/globalstyle";
import ThreeBees from "../../components/Icon/SVG/3Bees";
import { StyledSection } from "../../components/SectionTwoCol/style";
import { Rem } from "../../commons/Theme";
import Spacer from "../../components/Spacer/Spacer";
import Faq from "../../components/Faq/Faq";

const Beenergy = () => {
  const {
    pageImages, pageLabels, labels, languages, 
  } = useContext(DataContext);
  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";

  const imageData = pageImages.find(
    (e) => e.path === "/2020/images/business/beenergy/solarplant.jpg",
  );
  const { getListId } = useNewsletterListId();
  toast.configure();

  return (
    <>
      <Meta />
      <Hero variant="divided"  className="apply-typography">
        <HeroHeader
          title={
            <h1

              style={{ textTransform: "uppercase", paddingTop: Rem(100) }}
              dangerouslySetInnerHTML={{
                __html: "<span class=\"color-yellow\">BEE</span>NERGY",
              }}
            />
          }
          description={<h3 style={{ fontWeight: 100, paddingTop: "30px" }} dangerouslySetInnerHTML={{ __html:pageLabels.hero.paragraph }} />}
          variant="transparent"
        >
          <div className="mt-8">
            <Button onClick={useNavigate("#project")}>
              {pageLabels.hero.cta}
            </Button>
          </div>
        </HeroHeader>
        <HeroImageContainer
          variant="shape"
          style={{ position: "relative", alignSelf: "baseline" }}
        >
          <GatsbyImage
            loading="eager"
            layout="fullWidth"
            objectFit="cover"
            objectPosition="center"
            opacity="1"
            // style={{ "max-height": "750px", display: "block" }}
            image={getImage(imageData)}
            alt={imageData.alt}
          />
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              top: 0,
              left: 0,
              width: "100%",
              background:
                "linear-gradient(rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.2) 30%, rgba(255, 255, 255, 0.2) 72%, rgb(255, 255, 255) 100%)",
            }}
          />
        </HeroImageContainer>
       
        {/* <HeroFooter>
          <NewsletterBox
            title={labels.protect}
            newsletterListId={getListId("business")}
            newsletterButtonText={labels.NEWSLETTER}
            newsletterTitle={labels.footer.newsletterLabel}
            lang={currentLanguageCode}
            inputProps={{ placeholder: "Email"}}
            leftButtonProps={{
              text: labels.DETAILS,
              onClick: useNavigate("/adotta-un-alveare"),
            }}
            onSuccess={() =>
              toast.success("Welcome in 3Bee, Bzz", {
                containerId: "Alert",
                position: toast.POSITION.TOP_CENTER,
                autoClose: 10000,
              })
            }
            onError={() =>
              toast.error("Error. Contact us on info@3bee.com", {
                containerId: "Alert",
                position: toast.POSITION.TOP_CENTER,
                autoClose: 10000,
              })
            }
          />
        </HeroFooter> */}
      </Hero>

      <SectionDivider />

      <SectionTwoCol
        id="project"
        title={pageLabels.project.title}
        subtitle1={pageLabels.project.subtilte}
        ul={pageLabels.project.containsList.map(e => {return { text: e };})}
        cta={pageLabels.project.button}
        rightImage={false}
        topImage={true}
        to="#contacts"
        image="/2020/images/business/beenergy/beekeeper_photovoltaic.jpg"
      />

      <SectionDivider />

      <StyledHighlightedBusinessContainer>
    
        <StyledBusinessIconGroup>
          <StyledBusinessIconGroupTitle center color="yellow">
            {pageLabels.monitoring.title}
          </StyledBusinessIconGroupTitle>
          <StyledBusinessIconGroupItems>
            <BusinessIconTextItem
              maxWidth="300px"
              image="/2020/images/beenergy/satellite.png"
              label={pageLabels.monitoring.analysis.label}
              htmlText={pageLabels.monitoring.analysis.text}
            ></BusinessIconTextItem>
            <BusinessIconTextItem
              maxWidth="300px"

              image="/2020/images/beenergy/scan.png"
              label={pageLabels.monitoring.bioscan.label}
              htmlText={pageLabels.monitoring.bioscan.text}
            ></BusinessIconTextItem>
          </StyledBusinessIconGroupItems>
        </StyledBusinessIconGroup>
        <StyledBusinessIconGroup>
          <StyledBusinessIconGroupTitle center color="yellow">
            {pageLabels.rigeneration.title}
          </StyledBusinessIconGroupTitle>
          <StyledBusinessIconGroupItems>
            <BusinessIconTextItem
              maxWidth="300px"
              image="/2020/images/beenergy/hive.png"
              label={pageLabels.rigeneration.hiveTech.label}
              htmlText={pageLabels.rigeneration.hiveTech.text}
            ></BusinessIconTextItem>
            <BusinessIconTextItem
              maxWidth="300px"
              image="/2020/images/beenergy/polly.png"
              label={pageLabels.rigeneration.polly.label}
              htmlText={pageLabels.rigeneration.polly.text}
            ></BusinessIconTextItem>
            <BusinessIconTextItem
              maxWidth="300px"
              image="/2020/images/beenergy/piante.png"
              label={pageLabels.rigeneration.plants.label}
              htmlText={pageLabels.rigeneration.plants.text}
            ></BusinessIconTextItem>
          </StyledBusinessIconGroupItems>
        </StyledBusinessIconGroup>
      </StyledHighlightedBusinessContainer>
      <SectionDivider />

      <StyledQuantityContainer>
        <StyledQuantityDescription>
          <StyledTitle style={{ textTransform: "uppercase" }} dangerouslySetInnerHTML={{ __html: pageLabels.quantitativeTech.title }}> 
          </StyledTitle>
          <StyledSection.Content>
            <StyledParagraph small dangerouslySetInnerHTML={{ __html: pageLabels.quantitativeTech.description }} />
          </StyledSection.Content>
        </StyledQuantityDescription>
        <StyledSponsorContainer>
          <img alt="Horizon 2020 research and innovation programme" src="/2020/images/beenergy/europe-research-and-innovation-programme.png" loading="lazy" />
          <img alt="esa" src="/2020/images/beenergy/esa.png" loading="lazy" />
          <img alt="Consiglio nazionale delle ricerche" src="/2020/images/beenergy/consiglio-nazionale-delle-ricerche.png" loading="lazy" />
          <img alt="crea" src="/2020/images/beenergy/crea.png" loading="lazy" />
          <img alt="EIT" src="/2020/images/beenergy/eit.png" loading="lazy" />
          <img alt="Università degli studi di Firenze" src="/2020/images/beenergy/udf.png" loading="lazy" />
          <img alt="Università degli studi di Pisa" src="/2020/images/beenergy/udp.png" loading="lazy" />
          <img alt="Università degli studi di Napoli" src="/2020/images/beenergy/udn.png" loading="lazy" />
        </StyledSponsorContainer>
      </StyledQuantityContainer>
      
      <SectionDivider />

      <SectionTwoCol
        title={pageLabels.partner.title}
        subtitle1={pageLabels.partner.subtilte}
        cta={pageLabels.partner.button}
        rightImage={false}
        topImage={true}
        image="/2020/images/business/beenergy/hive_tech_sensor.jpg"
      />

      <SectionDivider />
      <Intro bigText={pageLabels.benefits.title} textAlign="center" />
      <BusinessIconText
        items={[
          {
            image: "/2020/images/business/beenergy/benefits/biodiversity.png",
            label: pageLabels.benefits.biodiversity.label,
            text: pageLabels.benefits.biodiversity.text,
          },
          {
            image: "/2020/images/beenergy/message_circle.png",
            label: pageLabels.benefits.stakeholder.label,
            text: pageLabels.benefits.stakeholder.text,
          },
          {
            image: "/2020/images/business/beenergy/benefits/impact.png",
            label: pageLabels.benefits.impact.label,
            text: pageLabels.benefits.impact.text,
          },
        ]}
      />

      <SectionDivider />

      <SectionTwoCol
        title={pageLabels.method.title}
        subtitle1={pageLabels.method.subtilte}
        cta={pageLabels.method.button}
        rightImage={true}
        topImage={true}
        to="#contacts"
        image="/2020/images/beenergy/stakeholders.jpg"
      />

      <SectionDivider />

      <StyledWhoHasGenerated>
        <StyledTitleContainer>
          <ThreeBees />
          <StyledTextContainer>
            <StyledTitle  style={{ lineHeight: 1, paddingBottom: Rem(20) }} dangerouslySetInnerHTML={{ __html: pageLabels.whoHasGenerated.title }}>
            </StyledTitle>
            <StyledParagraph small>{pageLabels.whoHasGenerated.description}</StyledParagraph>
          </StyledTextContainer>
        </StyledTitleContainer>
        <StyledWhoHasGeneratedItems>
          <img alt="Genagricola" src="/2020/images/beenergy/genagricola.png" loading="lazy" />
          <img alt="Mottola" src="/2020/images/beenergy/mottola.png" loading="lazy" />
        </StyledWhoHasGeneratedItems>
      </StyledWhoHasGenerated>
      <SectionDivider />
      <SectionDivider />

      <ContactFormPipe />
      <Spacer size={20}/>
      <Faq
        data={pageLabels.faq.dataFile.data}
      />
    </>
  );
};

export default Beenergy;
